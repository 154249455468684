<template>
  <Box>
    <SpacerRow />
  
    <Menu page="edit" />
    
    <TitleRow>
      Update User
    </TitleRow>
  
    <Row>
      <Column>
        <EmployeeEdit :isAdmin="false" />
        <Value />
      </Column>
    </Row>
    
  </Box>
</template>
       
<script>
import { mapGetters } from 'vuex'

import ConstUtils   from "@/utils/ConstUtils.js";
//import ContentUtils from "@/utils/ContentUtils.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Value from "@/portals/shared/library/value/Value.vue";

import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";

import EmployeeEdit from "@/views/portal/employees/edit/Edit.vue"

import Menu  from './Menu.vue';

export default {
  name: "portal-customer-operator-employee-edit",
  components: {
    Box,Row,Column, TitleRow,
    Value, SpacerRow,
    EmployeeEdit, Menu,
  },
  data() {
    return {
      id: this.$route.params["id"],
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'users_store',
                   'domain',
                ]),
    userData: function() {
      return this.user.data;
    },
    user: function() {
      return this.employee.user().find();
    },
    
    employeeData: function() {
      return this.employee.data;
    },
    employee: function() {
      return this.domain.employees().findById(this.id);
    },
  },
  watch: {
    
  },
  mounted: function() {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN, });
    }
  },
  methods: {
    
  },
}
</script>